import React, { useEffect, useState } from "react";
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import twelvecoffeesboard from '../images/12Coffees_blackboard_small.jpg';
import announcement from '../images/HouseOfTheDragon_coming_2024.png';
import '../css/Home.css';
import YoutubeEmbed from "../components/YoutubeEmbed";
import PodcastPlatforms from "../components/PodcastPlatforms";
import HostCard from "../components/HostCard";
import { useTranslation } from "react-i18next";
import { PRODUCCIONES_CHANGO_API_URL, YOUTUBE_CHANNEL_NAME } from "../common/constants";

const maxResultsFromAPI = 5;
var fetchUrl = `${PRODUCCIONES_CHANGO_API_URL}/info/youtube/${YOUTUBE_CHANNEL_NAME}?maxResults=${maxResultsFromAPI}`;
var fetchHostsInfo = `${PRODUCCIONES_CHANGO_API_URL}/info/hosts`;

function Home() {
  const { t, i18n } = useTranslation();
  const [latestVideo, setLatestVideo] = useState(null);
  const [hostsInfo, setHostsInfo] = useState();

  useEffect(() => {
    fetch(fetchUrl).then((response) => response.json()).then((jsonResponse) => {
        let latest = jsonResponse.items.filter(v => (v.snippet.title.charAt(0) == "#" || !v.snippet.title.includes("#short")))[0];
        setLatestVideo(latest.contentDetails.videoId);
      });

    fetch(fetchHostsInfo).then((response) => response.json()).then((jsonResponse) => {
        setHostsInfo(jsonResponse.values);
        let cellAdd = i18n.language == "en" ? 1 : 0;
      });
  }, []);

  return (
    <div className="home">
      {/* https://react-bootstrap.github.io/components/carousel/ */}
      <Carousel>
        <Carousel.Item interval={5000}>
          <div className="home-carousel-item">
            <img
              src={twelvecoffeesboard}
              alt="Merch slide"
            />
          </div>
          <Carousel.Caption>
            <h5>&#128073;{t("merch_store")}&#127978;</h5>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={6000}>
          <div className="home-carousel-item">
            <div className="home-video">
              <YoutubeEmbed embedId={latestVideo} t={t} />
            </div>
          </div>
          <Carousel.Caption>
            <h5>{t("watch.latest_video")}</h5>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={4000}>
          <div className="home-carousel-item">
            <img
              src={announcement}
              alt="Choose your team!"
            />
          </div>
          <Carousel.Caption className="video-coming-soon-caption">
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      <Card className="card-podcast">
        <Card.Header as="h3">Escucha el podcast en:</Card.Header>
        <Card.Body>
          <PodcastPlatforms />
          <CardGroup>
              <Card className="card-podcast-group">
                {/* iframe player code provided in buzzsprout */}
                <iframe id="player_iframe" src="https://www.buzzsprout.com/2377686?client_source=large_player&iframe=true&referrer=https%3A%2F%2Fwww.buzzsprout.com%2F2377686%2Fpodcast%2Fembed" 
                  loading="lazy"
                  width="100%"
                  height="375"
                  frameBorder="0"
                  scrolling="no"
                  title="La Casa del Dragón: 12Coffees Podcast">
                </iframe>
              </Card>
              <Card className="card-podcast-group small-card">
                <iframe
                  src="https://www.tiktok.com/embed/7396076048741436714"
                  allowfullscreen
                  width="100%"
                  height="100%"
                  allow="encrypted-media;">
                </iframe>
              </Card>
            </CardGroup>
        </Card.Body>
      </Card>

      {hostsInfo != null
      ?
        <Card className="card-hosts">
          <Card.Header as="h3">Hosts</Card.Header>
          <Card.Body>
            <Card.Title className="card-hosts-title">Single Origin and Blends</Card.Title>
              <CardGroup>
                {hostsInfo.map((host) =>(
                  <HostCard hostInfo={host} language={i18n.language} key={`${host[0]}_host_card`}/>
                ))}
              </CardGroup>
          </Card.Body>
        </Card>
      :
          <span className="visually-hidden">{t("loading")}...</span>
      }
    </div>
  );
}

export default Home;
